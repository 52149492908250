// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'

function renderYtSubscribeButton(channel) {
  const container = document.getElementById('YouTubeSubscribe')
  const options = {
    channelid: channel,
    layout: 'default',
    theme: 'default',
    layout: 'default',
    count: 'hidden',
  }
  gapi.ytsubscribe.render(container, options)
}

export const onClientEntry = () => {
  // window.onload = () => {
  //   renderYtSubscribeButton('UCxwG9Jwuidhhm1DRLC9Vwjw')
  //   if (
  //     window.document.getElementById('YouTubeSubscribe') &&
  //     window.document
  //       .getElementById('YouTubeSubscribe')
  //       .getElementsByTagName('iframe').length === 0
  //   ) {
  //     renderYtSubscribeButton('UCxwG9Jwuidhhm1DRLC9Vwjw')
  //     // window.location.reload()
  //     // window.document.getElementsByClassName('youtubeSubscribe').innerHTML =
  //     //   '<div class="g-ytsubscribe" data-channelid="UCxwG9Jwuidhhm1DRLC9Vwjw" data-layout="default" data-count="hidden"></div>'
  //   }
  // }
}
